import React from "react";
import { useParams, generatePath } from "react-router-dom";
import { ManageableItemShape } from "../../components";
import { Card, Divider, Spin } from "antd";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import {
  usePostManageableListItemSend,
  useGetManageableListItem,
  usePostDeleteManageableListItem,
} from "../../hooks";
import { ManageableUserList } from "../ManageableList/components";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../../../../system/constants/constantsUrl";

const ManageableItemEdit = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetManageableListItem(id);
  const [updateListItem] = usePostManageableListItemSend(id);
  const [deleteListItem] = usePostDeleteManageableListItem();

  const handleEditSubmit = (values) => {
    values.active = values.status;
    delete values.status;
    updateListItem(values);
  };
  const addUserToList = (userType, id) =>
    generatePath(APP_ROUTES.manage.manageable_list_add_user, {
      id,
      userType,
    });
  console.log("===", data);
  return (
    <MainLayout>
      <PageTemplate>
        <Spin spinning={isLoading}>
          {data && (
            <Card>
              <ManageableItemShape
                title={data?.data.title}
                initialValues={{
                  id: data?.data.id,
                  title: data?.data.title,
                  status: data?.data.active,
                }}
                handleDelete={() => deleteListItem(id)}
                isEdit
                submit={handleEditSubmit}
              />
              <Divider />
              <ManageableUserList
                list={data?.data.manager_logins}
                listType="manager"
                listId={id}
                title={_t("manager_list")}
                createPath={addUserToList("manager", id)}
              />
              <ManageableUserList
                list={data?.data.user_logins}
                listType="user"
                listId={id}
                title={_t("user_list")}
                createPath={addUserToList("user", id)}
              />
            </Card>
          )}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default ManageableItemEdit;
