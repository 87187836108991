import React from "react";
import commission_1 from "../../../assets/images/outer/commission_1.svg";
import commission_2 from "../../../assets/images/outer/commission_2.svg";
import commission_3 from "../../../assets/images/outer/commission_3.svg";
import commission_4 from "../../../assets/images/outer/commission_4.svg";
import { _t } from "../../Common/components/InjectIntlContext";
import CommissionCard from "./CommissionCard";
import { Link } from "react-router-dom";

const commissions = (_t: any) => [
  {
    title: _t("cpa_program"),
    image: commission_1,
    text: _t("cpa_program_desc"),
  },
  {
    title: _t("revshare_commission"),
    image: commission_2,
    text: _t("revshare_commission_desc"),
  },
  {
    title: _t("hybrid"),
    image: commission_3,
    text: _t("hybrid_desc"),
  },
  {
    title: _t("subaffiliate_program"),
    image: commission_4,
    text: _t("subaffiliate_program_desc"),
  },
];

const Commissions = () => {
  const commissionsTranslated = commissions(_t);
  const commissionItem = (card, idx) => (
    <CommissionCard info={card} key={card.title + idx} />
  );
  return (
    <>
      <section className="commissions">
        <div className="container">
          <div className="commissions-grid">
            {commissionsTranslated.map(commissionItem)}
          </div>
        </div>
      </section>
      <div className="get-started get-started--commissions">
        <div className="container">
          <Link to="/sign-up" className="btn btn--big">
            {_t("become_partner")}
          </Link>
        </div>
      </div>
    </>
  );
};

export default Commissions;
