import React, { useState } from "react";
import { useGetTotals } from "../../hooks/useGetTotals";
import { DashboardTotalItem } from "../../../../../modules/Dashboard/components/DashboardTotalItem";
import { isCurrencyField } from "../../../../../modules/Dashboard/constants";
import { CurrencyLabel } from "../../../../../modules/Common/components";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { DateRangePicker } from "../../../../../common/components";
import moment from "moment";
import { Button, Space, Spin, Typography } from "antd";
import { CellPartnerIncome } from "../../../../../modules/Dashboard/components/Totals/components";

const Totals = () => {
  const options = [
    "hits",
    "hosts",
    "regs",
    "qualified_players",
    "first_deposits",
    "deposits",
    "withdraws",
    "comppoints",
    "ngr",
    "ggr",
    "transaction_commission",
    "royalty",
  ];
  const [filters, setFilters] = useState({
    from: moment().startOf("month"),
    to: moment(),
  });
  const [dates, setDates] = useState({
    from: filters.from,
    to: filters.to,
  });
  const { data } = useGetTotals({
    from: filters.from.format("YYYY-MM-DD"),
    to: filters.to.format("YYYY-MM-DD"),
  });

  return (
    <div>
      <Typography.Title level={4}>Affiliate report totals</Typography.Title>
      <Space style={{ marginBottom: "15px" }}>
        <div style={{ maxWidth: "300px" }}>
          <DateRangePicker
            value={dates}
            onChange={(dates) => {
              setDates({
                from: dates[0],
                to: dates[1],
              });
            }}
          />
        </div>
        <Button
          size="large"
          shape="round"
          type="primary"
          onClick={() =>
            setFilters({
              ...filters,
              ...dates,
            })
          }
        >
          {_t("filter")}
        </Button>
      </Space>

      {data ? (
        <>
          <div className="dashboard-admin-totals">
            {data.totals && localStorage.getItem("role") === "admin" && (
              <CellPartnerIncome {...data.totals} />
            )}
            <div className="dashboard-admin-totals__grid">
              {options.map((column) => {
                const value = data.totals?.[column] || 0;
                return (
                  <div key={column}>
                    <div className="dashboard-admin-totals-item">
                      <p className="dashboard-admin-totals-item__title">
                        {_t(column)}
                      </p>
                      <p className="dashboard-admin-totals-item__value">
                        {isCurrencyField(column) ? (
                          <CurrencyLabel value={value} />
                        ) : (
                          value
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <div>
          <Spin spinning />
        </div>
      )}
    </div>
  );
};

export default Totals;
