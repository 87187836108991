import React, { useEffect } from "react";
import { ExcelButton } from "../../../../../../../modules/Dashboard/components/Filters/ExcelButton";
import { useGetReportFile } from "../../../../hooks/api";
import { Space, Spin, Table } from "antd";
import { _t } from "../../../../../../../modules/Common/components/InjectIntlContext";
import { config } from "../../config";
import { adminColumns, createColumns } from "../../../../utils";
import { RenderSubtotals } from "../../../../components";
import { useSubtotals } from "../../../../hooks/table";
import { useGetGeoDetails } from "../../utils";
import { API_ROUTES } from "../../../../../../../system/constants/apiRoutes";
import { ReactComponent as IconExcel } from "../../../../../../../images/icons/xlsx-file-icon.svg";
import { ReactComponent as IconCSV } from "../../../../../../../images/icons/csv-file-icon.svg";
const GeoDetails = ({ currentCountry, reqFilters }) => {
  const [getDetails, { data, isLoading }] = useGetGeoDetails();
  const [downloadXls, { isLoading: isXlsDownloading }] = useGetReportFile(
    API_ROUTES.reports.geo_details.path,
    `${currentCountry}_${API_ROUTES.reports.geo_details.filename}`,
    {
      ...reqFilters,
      export: "xls",
    }
  );
  const [downloadCSV, { isLoading: isCSVDownloading }] = useGetReportFile(
    API_ROUTES.reports.geo_details.path,
    `${currentCountry}_${API_ROUTES.reports.geo_details.filename}`,
    {
      ...reqFilters,
      export: "csv",
    }
  );

  useEffect(() => {
    getDetails({
      ...reqFilters,
      country: currentCountry,
    });
  }, [currentCountry]);
  const tableData: any = data && Object.values(data?.models);
  const subTotals = useSubtotals(
    tableData,
    config.subtotals,
    createColumns(config.columns)
  );
  const availableColumns =
    localStorage.getItem("role") === "admin"
      ? config.columns
      : config.columns.filter((column) => !adminColumns.includes(column));

  const translatedColumns = createColumns(availableColumns).map((c) => {
    return {
      ...c,
      title: _t(c.title),
    };
  });
  return (
    <div className="geo-detail-modal">
      <Space size={5}>
        <ExcelButton
          clickHandler={() => downloadXls()}
          isLoading={isXlsDownloading}
          icon={<IconExcel style={{ width: "30px", height: "30px" }} />}
        />
        <ExcelButton
          clickHandler={() => downloadCSV()}
          isLoading={isCSVDownloading}
          icon={<IconCSV style={{ width: "30px", height: "30px" }} />}
        />
      </Space>
      <Spin spinning={isLoading} />

      {!isLoading && data && (
        <Table
          columns={translatedColumns}
          dataSource={tableData}
          rowKey={(item) => item?.partner_id}
          pagination={false}
          summary={() => {
            return (
              <>
                {isLoading ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Spin spinning={isLoading} />
                  </div>
                ) : (
                  <>
                    {data && (
                      <RenderSubtotals
                        subTotals={subTotals}
                        translatedColumns={createColumns(availableColumns)}
                      />
                    )}
                  </>
                )}
              </>
            );
          }}
        />
      )}
    </div>
  );
};

export default GeoDetails;
