import React, { useEffect } from "react";
import Header from "../Landing/components/Header";
import Footer from "../Landing/components/Footer";

const LandingTemplate = (props) => {
  const { children, wrapClass, mainSlider } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="landing-pages">
      <Header />
      <main className="landing-pages__content">{children}</main>
      <Footer />
    </div>
  );
};

export default LandingTemplate;
