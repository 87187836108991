import React from "react";
import Form from "antd/lib/form";
import BtnDownImg from "../../../images/landings/btn-down.svg";
import { _t } from "../../Common/components/InjectIntlContext";
import { useSendContacts } from "../apiHooks";
import Button from "../../Common/components/Button";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import Input from "antd/lib/input";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../system/config";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import CustomButton from "../../Common/components/CustomButton";
import img from "../../../assets/images/contacts-img.jpg";
const Contacts = () => {
  const [SendContacts, { isLoading }] = useSendContacts();
  const recaptchaRef = React.createRef();
  const locale = getLocale();

  return (
    <section className="contacts custom-section">
      <div className="container">
        <div className="contact-form">
          <Form
            action={APP_ROUTES.contacts}
            className="contact-form"
            onFinish={SendContacts}
            layout="vertical"
          >
            <h2 className="custom-section__title">{_t("get_in_touch")}</h2>
            <p className="contact-form__text">
              {_t("contacts_welcome_message")}
            </p>

            <Form.Item
              name="name"
              label={_t("Name")}
              rules={[
                {
                  required: true,
                  message: _t("input_name"),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label={_t("Email")}
              rules={[
                {
                  required: true,
                  message: _t("input_email"),
                },
                {
                  type: "email",
                  message: _t("not_valid_email_message"),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="text"
              className="textarea-wrap"
              label={_t("your_message")}
              rules={[
                {
                  required: true,
                  message: _t("Please input details"),
                },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="g-recaptcha-response"
              rules={[
                {
                  required: true,
                  message: _t("recaptcha_required"),
                },
              ]}
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={config.google_recaptcha_sitekey}
                className={"recaptcha"}
                hl={locale}
              />
            </Form.Item>
            <button
              className="btn btn--second btn--big"
              type="submit"
              disabled={isLoading}
            >
              {_t("Send")}
            </button>
          </Form>
        </div>

        <div className="contact-img">
          <img src={img} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Contacts;
