import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import { _t } from "../../Common/components/InjectIntlContext";
import { createPortal } from "react-dom";
import { isAuthorized } from "../../../system/helpers/localstorageHelper";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import LandingLanguages from "./LandingLanguages";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const lastHash = useRef("");

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        lastHash.current = "";
      }, 100);
    }
  }, [location]);

  const headerContent = (
    <>
      <ul className="header-nav">
        <li>
          <Link to="/#benefits">{_t("benefits")}</Link>
        </li>

        <li>
          <Link to="/contacts">{_t("contacts")}</Link>
        </li>
        <li>
          <Link to="/testimonials">{_t("testimonials")}</Link>
        </li>
        {isAuthorized() && (
          <li>
            <Link to={APP_ROUTES.dashboard.general}>{_t("Dashboard")}</Link>
          </li>
        )}
        <li>
          <LandingLanguages />
        </li>
      </ul>
      {!isAuthorized() && (
        <div className="auth-buttons">
          <Link className="btn" to="/sign-up">
            {_t("sign_up")}
          </Link>
          <Link className="btn btn--border" to="/sign-in">
            {_t("sign_in")}
          </Link>
        </div>
      )}
    </>
  );
  return (
    <header className="landing-header">
      <div className="container">
        <div className="landing-header-content">
          <Link to="/" className="landing-logo">
            <img src={logo} alt="" />
          </Link>
          <div className="landing-header-content__main">{headerContent}</div>
          {isMenuOpen &&
            createPortal(
              <div role="dialog" className="mobile-menu">
                <div className="mobile-menu__wrap">
                  <button
                    className="close-menu"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <span></span>
                    <span></span>
                  </button>
                  {headerContent}
                </div>
              </div>,
              document.body
            )}
          <button className="burger-menu" onClick={() => setIsMenuOpen(true)}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
