import React from "react";
import icon_1 from "../../../assets/images/outer/benefit_1.png";
import icon_2 from "../../../assets/images/outer/benefit_2.png";
import icon_3 from "../../../assets/images/outer/benefit_3.png";
import icon_4 from "../../../assets/images/outer/benefit_4.png";
import icon_5 from "../../../assets/images/outer/benefit_5.png";
import icon_6 from "../../../assets/images/outer/benefit_6.png";
import { _t } from "../../Common/components/InjectIntlContext";
import { Typography } from "antd";
import BenefitCardItem from "./BenefitCardItem";
import { Link } from "react-router-dom";

const slides = (_t: any) => [
  {
    title: _t("benefit_1"),
    icon: icon_1,
  },
  {
    title: _t("benefit_2"),
    icon: icon_2,
  },
  {
    title: _t("benefit_3"),
    icon: icon_3,
  },
  {
    title: _t("benefit_4"),
    icon: icon_4,
  },
  {
    title: _t("benefit_5"),
    icon: icon_5,
  },
  {
    title: _t("benefit_6"),
    icon: icon_6,
  },
];

const OurBenefits = () => {
  const benefitCards = slides(_t);
  const benefit = (card, idx) => (
    <li className="benefits-grid__item" key={idx}>
      <BenefitCardItem info={card} />
    </li>
  );

  return (
    <section className="our-benefits custom-section" id="benefits">
      <div className="container">
        <h2 className="custom-section__title">{_t("our_benefits")}</h2>
        <ul className="benefits-grid">{benefitCards.map(benefit)}</ul>
        <div className="get-started">
          <Link to="/sign-up" className="btn btn--big">
            {_t("get_started")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default OurBenefits;
