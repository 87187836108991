import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingTemplate from "../../Layouts/LandingTemplate";
import { useTestimonials } from "../apiHooks";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { _t } from "../../Common/components/InjectIntlContext";
import img from "../../../assets/images/testimonial-img.jpg";
import Slider from "react-slick";
import { Spin } from "antd";
let slickSettings: any = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: 0,
  autoplay: false,
  autoplaySpeed: 8000,
  focusOnSelect: true,
  // adaptiveHeight: true,
  swipe: false,
};
const Testimonials = () => {
  const { data, isLoading } = useTestimonials({ language: getLocale() });

  useEffect(() => {
    if (window.screen.width < 1024) {
      const mobileSettings = {
        slidesToShow: 1,
        swipe: true,
        centerPadding: "30px",
      };
      slickSettings = {
        ...slickSettings,
        ...mobileSettings,
      };
    }
  }, []);
  return (
    <LandingTemplate>
      <div className="custom-section testimonial-section">
        <div className="container">
          <h2 className="custom-section__title">{_t("testimonials")}</h2>

          <div className="testimonial-img">
            <img src={img} alt="" />
          </div>
          <Spin spinning={isLoading}>
            {data?.testimonials && (
              <Slider {...slickSettings} className="testimonial-slider">
                {data.testimonials.map((item) => (
                  <div key={item.id} className="testimonial-card">
                    <h3 className="testimonial-card__title">{item.title}</h3>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.content }}
                      className="testimonial-card__text"
                    ></div>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="testimonial-card__link"
                    >
                      {item.url}
                    </a>
                  </div>
                ))}
              </Slider>
            )}
          </Spin>
        </div>
      </div>
    </LandingTemplate>
  );
};

export default Testimonials;
