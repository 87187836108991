import React from "react";
import { useSelector } from "react-redux";

import { Space, Spin, Table } from "antd";
import { ReactComponent as IconExcel } from "../../../../../images/icons/xlsx-file-icon.svg";
import { ReactComponent as IconCSV } from "../../../../../images/icons/csv-file-icon.svg";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { useGetAffiliateGeoDetail } from "./api";
import { RenderSubtotals } from "../../components";
import { adminColumns, createColumns } from "../../utils";
import { config } from "./config";
import { useSubtotals } from "../../hooks/table";
import { ExcelButton } from "../../../../../modules/Dashboard/components/Filters/ExcelButton";
import { useGetReportFile } from "../../hooks/api";
import { API_ROUTES } from "../../../../../system/constants/apiRoutes";
import moment from "moment";

const AffiliatesGeoReportDetail = ({ partnerId }) => {
  const { date, country } = useSelector((state) => state.reportGeoAffiliates);
  const params = {
    partner_id: partnerId,
    country,
    from: moment(date.from).format("YYYY-MM-DD"),
    to: moment(date.to).format("YYYY-MM-DD"),
  };
  const { data, isLoading } = useGetAffiliateGeoDetail(params);
  const detailColumns = config.columns.filter(
    (column) => !adminColumns.includes(column)
  );
  const subTotals = useSubtotals(
    data?.models?.data,
    config.subtotals,
    createColumns(detailColumns)
  );
  const translatedColumns = createColumns(detailColumns).map((c) => {
    return {
      ...c,
      title: _t(c.title),
    };
  });
  const [downloadXls, { isLoading: isXlsDownloading }] = useGetReportFile(
    API_ROUTES.reports.affiliates_geo_details.path,
    API_ROUTES.reports.affiliates_geo_details.filename,
    {
      ...params,
      export: "xls",
    }
  );
  const [downloadCSV, { isLoading: isCSVDownloading }] = useGetReportFile(
    API_ROUTES.reports.affiliates_geo_details.path,
    API_ROUTES.reports.affiliates_geo_details.filename,
    {
      ...params,
      export: "csv",
    }
  );
  return (
    <div className="affiliate-geo-detail-modal">
      <Space size={5}>
        <ExcelButton
          clickHandler={() => downloadXls()}
          isLoading={isXlsDownloading}
          icon={<IconExcel style={{ width: "30px", height: "30px" }} />}
        />
        <ExcelButton
          clickHandler={() => downloadCSV()}
          isLoading={isCSVDownloading}
          icon={<IconCSV style={{ width: "30px", height: "30px" }} />}
        />
      </Space>
      {!isLoading && data ? (
        <Table
          columns={translatedColumns}
          dataSource={data?.models.data.map((item) => ({
            ...item,
            date: date.from,
          }))}
          rowKey={(item) => item?.country}
          pagination={false}
          summary={() => {
            return (
              <>
                {isLoading ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Spin spinning={isLoading} />
                  </div>
                ) : (
                  <>
                    {data && (
                      <RenderSubtotals
                        subTotals={subTotals}
                        translatedColumns={createColumns(detailColumns)}
                      />
                    )}
                  </>
                )}
              </>
            );
          }}
        />
      ) : (
        <Spin spinning />
      )}
    </div>
  );
};

export default AffiliatesGeoReportDetail;
