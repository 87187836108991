import React from "react";
import { useTableData } from "../../hooks/table";
import { useGetReportFile } from "../../hooks/api";
import { ExcelButton } from "../../../../../modules/Dashboard/components/Filters/ExcelButton";
import { ReportTableWrapper } from "../ReportTableWrapper";
import { createColumnOptions } from "../../utils";
import { adminColumns, createColumns } from "../../utils";
import { getItem } from "../../../../../system/helpers/localstorageHelper";
import { permissions } from "../../../../../system/constants/roles";
import { Space } from "antd";
import { ReactComponent as IconExcel } from "../../../../../images/icons/xlsx-file-icon.svg";
import { ReactComponent as IconCSV } from "../../../../../images/icons/csv-file-icon.svg";

const ReportPage = ({ config, ReportFilters }) => {
  const {
    columns,
    hiddenColumns,
    columnsKey,
    stateSelector,
    api,
    fileName,
    apiKey,
    dispatchCallback,
    filteredColumns,
    subtotals,
    additionalFilterOptions,
  } = config;

  const { setFilters, filters, data, isLoading, reqFilters } = useTableData(
    stateSelector,
    dispatchCallback,
    api,
    apiKey
  );
  const [downloadXls, { isLoading: isXlsDownloading }] = useGetReportFile(
    api,
    fileName,
    {
      ...reqFilters,
      export: "xls",
    }
  );
  const [downloadCSV, { isLoading: isCSVDownloading }] = useGetReportFile(
    api,
    fileName,
    {
      ...reqFilters,
      export: "csv",
    }
  );

  return (
    <div>
      <ReportFilters
        setFilters={setFilters}
        initialValues={filters}
        excelButton={
          <Space size={5}>
            <ExcelButton
              clickHandler={() => downloadXls()}
              isLoading={isXlsDownloading}
              icon={<IconExcel style={{ width: "30px", height: "30px" }} />}
            />
            <ExcelButton
              clickHandler={() => downloadCSV()}
              isLoading={isCSVDownloading}
              icon={<IconCSV style={{ width: "30px", height: "30px" }} />}
            />
          </Space>
        }
      />
      <ReportTableWrapper
        data={data}
        isLoading={isLoading}
        allColumns={createColumns(columns)}
        hiddenColumns={hiddenColumns}
        visibleColumnsKey={columnsKey}
        columnsOptions={createColumnOptions(columns, hiddenColumns)}
        setFilters={setFilters}
        stateSelector={stateSelector}
        dispatchCallback={dispatchCallback}
        filteredColumns={filteredColumns}
        additionalFilterOptions={additionalFilterOptions}
        subtotalsColumns={subtotals}
      />
    </div>
  );
};

export default ReportPage;
