import { REPORT_STATE_SELECTOR } from "./utils";
import { API_ROUTES } from "../../../../../system/constants/apiRoutes";
import { REPORT_COLUMNS_KEY, REPORT_DATE_KEY } from "./utils";
import { updateReportFilters } from "./redux/actions";

export const config = {
  columns: [
    "id",
    "login",
    "status_text",
    "role_text",
    "traffic_type_label",
    "reg_date",
    "cash_type_label",
    "regs1",
    "regs2",
    "regs_diff",
    "regs_rel_diff",
    "hosts1",
    "hosts2",
    "hosts_diff",
    "hosts_rel_diff",
    "first_deposits1",
    "first_deposits2",
    "first_deposits_diff",
    "first_deposits_rel_diff",
    "ngr1",
    "ngr2",
    "ngr_diff",
    "ngr_rel_diff",
    "ggr1",
    "ggr2",
    "ggr_diff",
    "ggr_rel_diff",
    "royalty1",
    "royalty2",
    "royalty_diff",
    "royalty_rel_diff",
    "transaction_commission1",
    "transaction_commission2",
    "transaction_commission_diff",
    "transaction_commission_rel_diff",
    "rs_income1",
    "rs_income2",
    "rs_income_diff",
    "rs_income_rel_diff",
    "assured_income1",
    "assured_income2",
    "assured_income_diff",
    "assured_income_rel_diff",
    "in_out1",
    "in_out2",
    "in_out_diff",
    "in_out_rel_diff",
    "ngcp1",
    "ngcp2",
    "ngcp_diff",
    "ngcp_rel_diff",
    "partner_income1",
    "partner_income2",
    "partner_income_diff",
    "partner_income_rel_diff",
  ],

  hiddenColumns: [],
  subtotals: [
    "regs1",
    "regs2",
    "regs_diff",
    "regs_rel_diff",
    "hosts1",
    "hosts2",
    "hosts_diff",
    "hosts_rel_diff",
    "first_deposits1",
    "first_deposits2",
    "first_deposits_diff",
    "first_deposits_rel_diff",
    "ngr1",
    "ngr2",
    "ngr_diff",
    "ngr_rel_diff",
    "ggr1",
    "ggr2",
    "ggr_diff",
    "ggr_rel_diff",
    "royalty1",
    "royalty2",
    "royalty_diff",
    "royalty_rel_diff",
    "transaction_commission1",
    "transaction_commission2",
    "transaction_commission_diff",
    "transaction_commission_rel_diff",
    "rs_income1",
    "rs_income2",
    "rs_income_diff",
    "rs_income_rel_diff",
    "assured_income1",
    "assured_income2",
    "assured_income_diff",
    "assured_income_rel_diff",
    "in_out1",
    "in_out2",
    "in_out_diff",
    "in_out_rel_diff",
    "ngcp1",
    "ngcp2",
    "ngcp_diff",
    "ngcp_rel_diff",
    "partner_income1",
    "partner_income2",
    "partner_income_diff",
    "partner_income_rel_diff",
  ],

  filteredColumns: [
    "id",
    "login",
    "status_text",
    "role_text",
    "traffic_type_label",
    "reg_date",
    "cash_type_label",
    "regs1",
    "regs2",
    "regs_diff",
    "regs_rel_diff",
    "hosts1",
    "hosts2",
    "hosts_diff",
    "hosts_rel_diff",
    "first_deposits1",
    "first_deposits2",
    "first_deposits_diff",
    "first_deposits_rel_diff",
    "ngr1",
    "ngr2",
    "ngr_diff",
    "ngr_rel_diff",
    "ggr1",
    "ggr2",
    "ggr_diff",
    "ggr_rel_diff",
    "royalty1",
    "royalty2",
    "royalty_diff",
    "royalty_rel_diff",
    "transaction_commission1",
    "transaction_commission2",
    "transaction_commission_diff",
    "transaction_commission_rel_diff",
    "rs_income1",
    "rs_income2",
    "rs_income_diff",
    "rs_income_rel_diff",
    "assured_income1",
    "assured_income2",
    "assured_income_diff",
    "assured_income_rel_diff",
    "in_out1",
    "in_out2",
    "in_out_diff",
    "in_out_rel_diff",
    "ngcp1",
    "ngcp2",
    "ngcp_diff",
    "ngcp_rel_diff",
    "partner_income1",
    "partner_income2",
    "partner_income_diff",
    "partner_income_rel_diff",
  ],
  columnsKey: REPORT_COLUMNS_KEY,
  dateKey: REPORT_DATE_KEY,
  stateSelector: REPORT_STATE_SELECTOR,
  api: API_ROUTES.reports.affiliates_difference.path,
  apiKey: API_ROUTES.reports.affiliates_difference.key,
  fileName: API_ROUTES.reports.affiliates_difference.filename,
  dispatchCallback: updateReportFilters,
};
