import React from "react";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import { Button, Card, Col, Form, Input, Row, Select, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { ManageableItemShapeInterface } from "./types";

const ManageableItemShape: React.FC<ManageableItemShapeInterface> = ({
  title,
  initialValues,
  isEdit,
  handleDelete,
  submit,
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const history = useHistory();

  const handleSubmit = (values) => {
    submit(values);
  };
  return (
    <div className="manageable-list-info">
      <div className="manageable-list-info__top">
        <Typography.Title level={5}>
          {title || _t("manageable_list")}
        </Typography.Title>
        {isEdit && (
          <Button
            type="primary"
            size="large"
            shape="round"
            onClick={handleDelete}
            htmlType="button"
          >
            {_t("delete")}
          </Button>
        )}
      </div>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Row>
          <Col span={24} lg={8}>
            {isEdit && (
              <Form.Item name="id" initialValue={initialValues?.id} hidden>
                <Input />
              </Form.Item>
            )}
            <Form.Item
              label={_t("title")}
              name="title"
              initialValue={title}
              rules={[{ required: true, message: _t("required_field") }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={_t("status")}
              name="status"
              initialValue={"1"}
              // initialValue={initialValues.status + ""}
              hidden
            >
              <Select>
                <Option value="1">{_t("active")}</Option>
                <Option value="0">{_t("disabled")}</Option>
              </Select>
            </Form.Item>
            <div className="manageable-list-info__controls">
              <Button
                onClick={() => history.goBack()}
                size="large"
                shape="round"
              >
                {_t("back")}
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                shape="round"
              >
                {_t("submit")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ManageableItemShape;
