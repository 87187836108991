import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import { useTranslation } from "../apiHooks";
import { Spin } from "antd";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import img from "../../../assets/images/terms-img.jpg";

const TermsPage = () => {
  const { data, isLoading } = useTranslation({
    language: getLocale(),
    name: "conditions",
  });
  return (
    <section className="terms custom-section">
      <div className="container">
        <div className="terms-top">
          <h2 className="terms-top__title">{_t("conditions")}</h2>
          <div className="terms-top__img">
            <img src={img} alt="" />
          </div>
        </div>
        <Spin spinning={isLoading}>
          <div
            className="terms-content"
            dangerouslySetInnerHTML={{ __html: data?.text }}
          ></div>
        </Spin>
      </div>
    </section>
  );
};

export default TermsPage;
