import React, { useState } from "react";
import FormItem from "antd/es/form/FormItem";
import Input from "antd/lib/input";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import Form from "antd/lib/form";
import { Button } from "antd";
import { usePostControlUserType, usePostDeleteUserType } from "../api";

const UserTypeForm = ({ initialValues, setIsOpen }: any) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [mutate, { isLoading }] = usePostControlUserType();
  const [deleteUserType] = usePostDeleteUserType();
  return confirmDelete ? (
    <>
      <p>
        Delete user type:{" "}
        <b style={{ fontSize: "16px" }}>{initialValues?.title}</b>
      </p>
      <Button
        type="dashed"
        size="large"
        shape="round"
        htmlType="button"
        onClick={() => setConfirmDelete(false)}
      >
        {_t("No")}
      </Button>{" "}
      <Button
        type="primary"
        size="large"
        shape="round"
        htmlType="button"
        onClick={() => deleteUserType(initialValues.id)}
      >
        {_t("delete")}
      </Button>{" "}
    </>
  ) : (
    <Form
      initialValues={initialValues}
      layout="vertical"
      onFinish={(values) => {
        mutate({
          title: values.title,
          id: initialValues?.id || null,
        });
        setIsOpen(false);
      }}
    >
      <FormItem
        name="title"
        rules={[
          { required: true, message: "Please, write a name for a new type" },
        ]}
      >
        <Input placeholder={_t("Name")} />
      </FormItem>
      {initialValues && (
        <Button
          type="dashed"
          size="large"
          shape="round"
          htmlType="button"
          onClick={() => setConfirmDelete(true)}
        >
          {_t("delete")}
        </Button>
      )}{" "}
      <Button type="primary" size="large" shape="round" htmlType="submit">
        {_t("Save")}
      </Button>{" "}
    </Form>
  );
};

export default UserTypeForm;
