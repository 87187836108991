import React from "react";
import { Space, Row, Col } from "antd";
import { Typography } from "antd";

export function PageTemplate({
  title,
  children,
  actions,
  additionalClass,
}: React.PropsWithChildren<{
  title?: string;
  actions?: JSX.Element;
  additionalClass?: string;
}>) {
  return (
    <Space
      direction="vertical"
      style={{ display: "flex" }}
      size={38}
      className={additionalClass}
    >
      {(title || actions) && (
        <Row align="middle" gutter={5}>
          <Col flex="auto">
            {title && (
              <Typography.Title ellipsis style={{ margin: 0 }} level={4}>
                {title}
              </Typography.Title>
            )}
          </Col>
          <Col flex="0 0 auto">{actions}</Col>
        </Row>
      )}
      <Row>
        <Col span="24">{children}</Col>
      </Row>
    </Space>
  );
}

type a = { [key: string]: any };
