import React from "react";
import { Card } from "antd";
import { _t } from "../../Common/components/InjectIntlContext";
import { NavLink } from "react-router-dom";
import Button from "../../Common/components/Button";

export function WelcomeForm(data: any) {
  return (
    <Card title={_t("welcome")} style={{ height: "100%", textAlign: "center" }}>
      <div
        dangerouslySetInnerHTML={{
          __html: _t("successful_registration_message"),
        }}
      />
      <br />
      <NavLink to={"/sign-in"}>
        <Button type="primary" title={_t("sign_in")} />
      </NavLink>
    </Card>
  );
}
