import React from "react";
import { Button } from "antd";
import { ReactComponent as IconExcel } from "../../../../images/icons/excel.svg";

export function ExcelButton({ clickHandler, isLoading, icon = <IconExcel /> }) {
  return (
    <Button
      disabled={isLoading}
      size="large"
      shape="circle"
      icon={icon}
      onClick={clickHandler}
    />
  );
}
