import React, { useState } from "react";
import { Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
import { UserTypeForm } from "./index";

const CreateNewUserType = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        size="middle"
        shape="circle"
        icon={<PlusOutlined />}
        onClick={() => setIsOpen(true)}
        style={{ marginLeft: "10px" }}
      />
      {isOpen && (
        <Modal
          visible={true}
          onOk={() => setIsOpen(false)}
          onCancel={() => setIsOpen(false)}
          footer={false}
          width={400}
          title={_t("edit")}
        >
          <UserTypeForm setIsOpen={setIsOpen} />
        </Modal>
      )}
    </>
  );
};

export default CreateNewUserType;
