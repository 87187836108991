import React from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import Commissions from "../components/Commissions";
import OurBenefits from "../components/OurBenefits";
import HowItWorks from "../components/HowItWorks";
import { _t } from "../../Common/components/InjectIntlContext";
import { Link } from "react-router-dom";
import img_1 from "../../../assets/images/banner_1.jpg";
import img_2 from "../../../assets/images/banner_2.jpg";
const Landing = () => {
  return (
    <LandingTemplate>
      <div className="main-banner">
        <div className="container">
          <h1 className="main-banner-title">
            The home of <br />
            market-leading
            <br />
            affiliates for
            <br />
            betting & casino
          </h1>
          <div className="main-banner-image main-banner-image--1">
            <img src={img_1} alt="" />
          </div>
          <div className="main-banner-image main-banner-image--2">
            <img src={img_2} alt="" />
          </div>
        </div>
      </div>
      <Commissions />
      <OurBenefits />
      <HowItWorks />
      <section className="contacts-section custom-section">
        <div className="container">
          <h2 className="custom-section__title">{_t("contacts")}</h2>

          <div className="get-started">
            <Link to="/sign-up" className="btn btn--big">
              {_t("get_started")}
            </Link>
          </div>
        </div>
      </section>
    </LandingTemplate>
  );
};

export default Landing;
