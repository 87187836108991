import { useMutation, useQueryCache } from "react-query";
import { notify } from "../../../../../common/helpers";
import { postControlUserType, postDeleteUserType } from "../api";

export const usePostDeleteUserType = () => {
  const queryCache = useQueryCache();
  return useMutation(postDeleteUserType, {
    onSuccess: () => {
      notify("success", "User types was deleted");
      queryCache.invalidateQueries(["user-profile-types"]);
    },
  });
};
