import React, { useState } from "react";
import { Button, Modal } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { UserTypeForm } from "../components";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";

const Controls = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        size="middle"
        shape="circle"
        icon={<EditOutlined />}
        onClick={() => setIsOpen(true)}
        style={{ marginRight: "10px" }}
      ></Button>{" "}
      {isOpen && (
        <Modal
          visible={true}
          onOk={() => setIsOpen(false)}
          onCancel={() => setIsOpen(false)}
          footer={false}
          width={400}
          title={_t("edit")}
        >
          <UserTypeForm initialValues={data} setIsOpen={setIsOpen} />
        </Modal>
      )}
    </>
  );
};

export default Controls;
