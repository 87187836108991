import { REPORT_STATE_SELECTOR } from "./utils";
import { API_ROUTES } from "../../../../../system/constants/apiRoutes";
import { REPORT_COLUMNS_KEY, REPORT_DATE_KEY } from "./utils";
import { updateReportFilters } from "./redux/actions";

export const config = {
  columns: [
    "gamer_id",
    "project_name",
    "partner_id",
    "login",
    "status_label",
    "role_label",
    "traffic_type_label",
    "promo_code",
    "reg_date",
    "first_deposit_date",
    "reg_ip",
    "sub_id",
    "sub_id2",
    "country",
    "deposits_count",
    "first_deposit",
    "deposits",
    "withdraws",
    "ngr",
    "ggr",
    "royalty",
    "transaction_commission",
    "rs_income",
    "assured_income",
    "bonuses",
    "partner_income",
    "bets",
  ],
  hiddenColumns: [],
  subtotals: [
    "deposits_count",
    "first_deposit",
    "deposits",
    "withdraws",
    "ngr",
    "ggr",
    "royalty",
    "transaction_commission",
    "rs_income",
    "assured_income",
    "bonuses",
    "partner_income",
    "bets",
  ],
  filteredColumns: [
    "gamer_id",
    "project_name",
    "partner_id",
    "login",
    "status_label",
    "role_label",
    "traffic_type_label",
    "promo_code",
    "reg_date",
    "first_deposit_date",
    "reg_ip",
    "sub_id",
    "sub_id2",
    "country",
    "deposits_count",
    "first_deposit",
    "deposits",
    "withdraws",
    "ngr",
    "ggr",
    "royalty",
    "transaction_commission",
    "rs_income",
    "assured_income",
    "bonuses",
    "partner_income",
    "bets",
  ],
  columnsKey: REPORT_COLUMNS_KEY,
  dateKey: REPORT_DATE_KEY,
  stateSelector: REPORT_STATE_SELECTOR,
  api: API_ROUTES.reports.gamers.path,
  apiKey: API_ROUTES.reports.gamers.key,
  fileName: API_ROUTES.reports.gamers.filename,
  dispatchCallback: updateReportFilters,
};
