import React, { useState, useEffect } from "react";
import { Filters } from "../../../Common/components/Filters";
import { Space, Form, Table } from "antd";
import { useGamerReport } from "../../apiHooks";
import moment from "moment";
import { gamerProfileColumns } from "../../constants";
import { _t } from "../../../Common/components/InjectIntlContext";
import { NoData } from "../../../Common/components/NoData";
import { setFormInputValue } from "../../../../common/helpers";
import DateRangePicker from "../../../../common/components/DateRangePicker/DateRangePicker";
import { useForm } from "antd/es/form/Form";

const useTableData = ({ gamerId, projectId }) => {
  const pageSize = 50;

  const getInitialDates = () => {
    const savedDates = JSON.parse(
      localStorage.getItem("dashboard_gamer_profile_dates")
    );

    if (savedDates) {
      return {
        from: moment(savedDates.from),
        to: moment(savedDates.to),
      };
    } else {
      return {
        from: moment().startOf("month"),
        to: moment(),
      };
    }
  };
  const [dates, setDates] = useState(getInitialDates());
  const [page, setPage] = useState(1);
  const { isLoading, data } = useGamerReport({
    gamer_id: gamerId,
    project_id: projectId,
    date_from: moment(dates.from).format("YYYY-MM-DD"),
    date_to: moment(dates.to).format("YYYY-MM-DD"),
    page,
    per_page: pageSize,
  } as any);

  useEffect(() => {
    setPage(1);
  }, [dates]);

  return { setDates, dates, isLoading, data, page, setPage, pageSize };
};

export function StatsTable({ gamerId, projectId }) {
  const {
    setDates,
    dates,
    isLoading,
    data,
    setPage,
    page,
    pageSize,
  } = useTableData({
    gamerId,
    projectId,
  });
  const tableColumns = gamerProfileColumns.map((c) => {
    return {
      ...c,
      title: _t(c.title),
    };
  });
  const [form] = useForm();

  return (
    <>
      <Filters
        onApply={(v) => {
          const dates = {
            from: v.date[0],
            to: v.date[1],
          };
          setDates(dates);
          localStorage.setItem(
            "dashboard_gamer_profile_dates",
            JSON.stringify(dates)
          );
        }}
        initialValues={{
          date: {
            ...dates,
          },
        }}
        mainFilters={
          <Space size={22} direction="horizontal" align="center">
            <Form.Item name="date">
              <DateRangePicker
                onChange={(dates) => {
                  setFormInputValue(
                    "date",
                    {
                      from: dates[0],
                      to: dates[1],
                    },
                    form
                  );
                }}
              />
            </Form.Item>
          </Space>
        }
      />
      <Table
        loading={isLoading}
        columns={tableColumns}
        dataSource={data?.stats?.data}
        rowKey={(r, i) => i}
        pagination={{
          total: data?.stats.total,
          hideOnSinglePage: true,
          onChange: setPage,
          current: page,
          pageSize: pageSize,
          showSizeChanger: false,
          position: ["bottomCenter"],
        }}
        locale={{ emptyText: <NoData /> }}
      />
    </>
  );
}
