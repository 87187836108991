import axiosClient from "../../../../system/helpers/axiosConfig";
import FileSaver from "file-saver";

export const getReportFile = (api, params, fileName) => {
  return axiosClient
    .post(api, params, {
      responseType: "blob",
    })
    .then((r) => {
      const fileType = params.export === "csv" ? "csv" : "xlsx";
      FileSaver.saveAs(
        r.data,
        `${fileName}_${params.from}_${params.to}.${fileType}`
      );
    });
};
