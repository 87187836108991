import { useMutation, useQueryCache } from "react-query";
import { notify } from "../../../../../common/helpers";
import { postControlUserType } from "../api";

export const usePostControlUserType = () => {
  const queryCache = useQueryCache();
  return useMutation(postControlUserType, {
    onSuccess: () => {
      notify("success", "User types was updated");
      queryCache.invalidateQueries(["user-profile-types"]);
    },
  });
};
