import React from "react";
import { ReportTableWidget } from "../components/ReportTable/ReportWidget";
import { DashboardFilters } from "../components/Filters/Filters";
import { Space, Divider, Form } from "antd";
import { TableTotals } from "../components/Totals/TableTotals";
import { CollapsebleChart } from "../components/Charts/LineChart";
import { usePromoData, GroupSelect } from "./Promos";
import { allSubIdColumns } from "../constants";
import { PageTemplate } from "./PageTemplate";
import { useGeneralReportListFile } from "../apiHooks";
import { ExcelButton } from "../components/Filters/ExcelButton";
import { _t } from "../../Common/components/InjectIntlContext";
import Input from "antd/lib/input";

export const SubId = () => {
  const {
    isLoading,
    data,
    totals,
    promoOptions,
    chartFilters,
    showPromoField,
    reqFilters,
  } = usePromoData({
    day: "by-days-sub-id",
    total: "by-sub-id",
  });

  const [getFile, { isLoading: isFileLoading }] = useGeneralReportListFile(
    reqFilters as any
  );
  const clickHandler = () => {
    getFile();
  };

  return (
    <PageTemplate>
      <Space
        size={0}
        direction="vertical"
        style={{ width: "100%", display: "flex" }}
      >
        <DashboardFilters
          fields={
            <>
              {showPromoField && (
                <Form.Item name="promo_id">
                  <GroupSelect
                    defaultValue={null}
                    style={{ minWidth: 200, maxWidth: 300 }}
                    loading={isLoading}
                    allowClear={false}
                    options={promoOptions}
                    showSearch
                    optionFilterProp={"search"}
                  />
                </Form.Item>
              )}
              <Form.Item name="sub_id2" label={_t("sub_id2")}>
                <Input placeholder={_t("sub_id2")} />
              </Form.Item>
              <ExcelButton
                clickHandler={clickHandler}
                isLoading={isFileLoading}
              />
            </>
          }
        />
        <Divider style={{ margin: 0 }} />
        <CollapsebleChart filters={chartFilters} />
      </Space>

      <TableTotals totals={totals} isLoading={isLoading} />
      <ReportTableWidget
        showGroupBy
        data={data}
        isLoading={isLoading}
        allColumns={allSubIdColumns}
        columnsKey="dashboard_sub_id_columns"
      />
    </PageTemplate>
  );
};
