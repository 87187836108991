import React from "react";
import { FaqResponse } from "../../api";
import InfoCard from "../../../Landing/components/InfoCard";
import classnames from "classnames";
import classNames from "classnames";

type FaqTitlesProps = {
  data: FaqResponse;
  loading: boolean;
  title: string;
  tileClass: string;
  selected: string;
  requestParam: string;
  setFilter: (v) => void;
};

export function FaqTitle({
  title,
  tileClass,
  selected,
  requestParam,
  setFilter,
}: FaqTitlesProps) {
  return (
    <button
      onClick={() => setFilter(requestParam)}
      className={classNames("faq-nav-card", "btn", "btn--second", {
        "btn--border": !selected,
      })}
    >
      {title}
    </button>
  );
}
