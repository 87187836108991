import { API_ROUTES } from "../../../../../system/constants/apiRoutes";
import { REPORT_COLUMNS_KEY, REPORT_DATE_KEY } from "./utils";

export const config = {
  columns: [
    "partner_id",
    "partner_login",
    "country",
    "country_full",
    "status_label",
    "role_label",
    "reg_date",
    "cash_type_label",
    "wallet",
    "traffic_type_label",
    "rounds",
    "multiacc_count",
    "regs",
    "first_deposits",
    "deposits_count",
    "rd",
    "rd_percent",
    "deposits",
    "withdraws",
    "comppoints",
    "ngr",
    "ggr",
    "royalty",
    "transaction_commission",
    "rs_income",
    "assured_income",
    "partner_income",
  ],

  subtotals: [
    "rounds",
    "multiacc_count",
    "regs",
    "first_deposits",
    "deposits_count",
    "rd",
    "rd_percent",
    "deposits",
    "withdraws",
    "comppoints",
    "ngr",
    "ggr",
    "royalty",
    "transaction_commission",
    "rs_income",
    "assured_income",
    "partner_income",
  ],

  columnsKey: REPORT_COLUMNS_KEY,
  dateKey: REPORT_DATE_KEY,
  api: API_ROUTES.reports.affiliates_geo_details.path,
  apiKey: API_ROUTES.reports.affiliates_geo_details.key,
  fileName: API_ROUTES.reports.affiliates_geo_details.filename,
};
