import React from "react";
import { NavLink } from "react-router-dom";
import { _t } from "../../Common/components/InjectIntlContext";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";
import icon_1 from "../../../assets/images/footer/footer_icon_1.png";
import icon_2 from "../../../assets/images/footer/footer_icon_2.png";
import icon_3 from "../../../assets/images/footer/footer_icon_3.png";
import icon_4 from "../../../assets/images/footer/footer_icon_4.png";
import icon_5 from "../../../assets/images/footer/footer_icon_5.png";

const links = (_t: any) => [
  {
    title: _t("FAQ"),
    path: APP_ROUTES.faq.index,
  },
  {
    title: _t("conditions"),
    path: APP_ROUTES.terms,
  },
  {
    title: _t("postback_options"),
    path: APP_ROUTES.postback_options,
  },
  {
    title: _t("news"),
    path: APP_ROUTES.news.list,
  },
];
const socialLinks = (_t: any) => [
  {
    link: _t("social_link_1"),
    image: icon_1,
  },
  {
    link: _t("social_link_2"),
    image: icon_2,
  },
  // {
  //   link: _t("social_link_3"),
  //   image: icon_3,
  // },
  {
    link: _t("social_link_4"),
    image: icon_4,
  },
  {
    link: _t("social_link_5"),
    image: icon_5,
  },
];

const Footer = () => {
  const translatedLinks = links(_t);
  const socials = socialLinks(_t);
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <ul className="footer__social">
            {socials.map((item) => (
              <li key={item.link}>
                <a href={item.link} target="_blank" rel="noreferrer noopener">
                  <img src={item.image} alt="" />
                </a>
              </li>
            ))}
          </ul>
          <ul className="footer__menu">
            {translatedLinks.map((link) => (
              <li key={link.title} className="footer__menu-item">
                <NavLink to={link.path}>{link.title}</NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
