import { history } from "../store";
import { tokenTimeout, setUpAuthHandlers } from "../helpers/tokenHelper";
import {
  logoutHelper,
  loginHelper,
  getAdminToken,
  getItem,
} from "../helpers/localstorageHelper";
import { refreshTokenAction } from "../../modules/Auth/actions";
import { APP_ROUTES } from "../constants/constantsUrl";

setUpAuthHandlers(); //add token to request headers and handle response

export default (store) => (next) => (action) => {
  if (
    (action.payload && action.type === "AUTH_SUCCESS") ||
    action.type === "REFRESH_TOKEN_SUCCESS"
  ) {
    const data = action.payload;
    loginHelper(data);
    tokenTimeout(() => store.dispatch(refreshTokenAction()));

    const adminRedirect = () => {
      return getItem("role") === "admin"
        ? history.push(APP_ROUTES.dashboard.admin)
        : history.push(APP_ROUTES.dashboard.general);
    };
    if (action.type === "AUTH_SUCCESS" && !!getAdminToken() === false) {
      if (
        history.location.pathname !== APP_ROUTES.login.main &&
        history.location.pathname !== "/sign-up"
      ) {
        history.push(history.location.pathname + history.location.search);
      } else {
        adminRedirect();
      }
    } else if (action.type === "AUTH_SUCCESS") {
      adminRedirect();
    }
  }
  if (action.type === "LOGOUT_SUCCESS") {
    logoutHelper();
  }

  return next(action);
};
