import React, { useMemo } from "react";
import { ReportTableWidget } from "../components/ReportTable/ReportWidget";
import { DashboardFilters } from "../components/Filters/Filters";
import { Space, Divider, Select, Form } from "antd";
import { TableTotals } from "../components/Totals/TableTotals";
import { CollapsebleChart } from "../components/Charts/LineChart";
import { useFilters } from "../hooks";
import { useGeneralReportList, useGeneralReportListFile } from "../apiHooks";
import { createPromoOptions } from "../utils";
import { allPromoColumns } from "../constants";
import { PageTemplate } from "./PageTemplate";
import { ExcelButton } from "../components/Filters/ExcelButton";

const { Option, OptGroup } = Select;

export const GroupSelect = ({ options, ...props }) => (
  <Select style={{ width: 200 }} {...props}>
    {options.map(function renderOptions(option) {
      return Array.isArray(option.children) ? (
        <OptGroup key={option.label} label={option.label} search={option.label}>
          {option.children.map(renderOptions)}
        </OptGroup>
      ) : (
        <Option
          title={option.value}
          key={option.value}
          value={option.value}
          search={option.search}
        >
          {option.label}
        </Option>
      );
    })}
  </Select>
);

export const usePromoData = (scopes: { day: string; total: string }) => {
  const [
    { date, page_size, promo_id, period, columns, page, groupBy, sub_id2 },
  ] = useFilters();
  const scope = scopes[groupBy];
  const showPromoField = groupBy !== "total";
  const reqFilters = {
    from: date.from,
    to: date.to,
    scope,
    promo_id: groupBy === "day" ? promo_id : null,
    sub_id2: sub_id2,
  };

  const { isLoading, data } = useGeneralReportList({
    ...reqFilters,
    page,
    page_size,
  } as any);

  const chartFilters = {
    from: date.from,
    to: date.to,
    period,
    promo_id,
    columns,
  };
  const totals = data?.totals;
  const promoOptions = useMemo(() => {
    return createPromoOptions(data);
  }, [data?.promos]);

  return {
    isLoading,
    data,
    totals,
    promoOptions,
    chartFilters,
    showPromoField,
    reqFilters,
  };
};

export const Promos = () => {
  const {
    isLoading,
    data,
    totals,
    promoOptions,
    chartFilters,
    showPromoField,
    reqFilters,
  } = usePromoData({
    day: "by-days-promos",
    total: "by-promos",
  });

  const [getFile, { isLoading: isFileLoading }] = useGeneralReportListFile(
    reqFilters as any
  );
  const clickHandler = () => {
    getFile();
  };

  return (
    <PageTemplate>
      <Space
        size={0}
        direction="vertical"
        style={{ width: "100%", display: "flex" }}
      >
        <DashboardFilters
          fields={
            <>
              {showPromoField && (
                <Form.Item name="promo_id">
                  <GroupSelect
                    defaultValue={null}
                    style={{ minWidth: 200, maxWidth: 300 }}
                    loading={isLoading}
                    allowClear={false}
                    options={promoOptions}
                    showSearch
                    optionFilterProp={"search"}
                  />
                </Form.Item>
              )}
              <ExcelButton
                clickHandler={clickHandler}
                isLoading={isFileLoading}
              />
            </>
          }
        />
        <Divider style={{ margin: 0 }} />
        <CollapsebleChart filters={chartFilters} />
      </Space>

      <TableTotals totals={totals} isLoading={isLoading} />
      <ReportTableWidget
        data={data}
        isLoading={isLoading}
        allColumns={allPromoColumns}
        columnsKey="dashboard_promos_columns"
        showGroupBy
      />
    </PageTemplate>
  );
};
