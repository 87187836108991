import React from "react";

const HowItWorkCard = ({ info, number }) => {
  const { title, icon } = info;
  return (
    <div className="how-it-works-card">
      <div className="how-it-works-card__number">{number}</div>
      <p className="how-it-works-card__title">{title}</p>
    </div>
  );
};

export default HowItWorkCard;
