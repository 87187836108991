import React from "react";
import MainLayout from "../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../modules/Layouts/PageTemplate";
import { _t } from "../../../../modules/Common/components/InjectIntlContext";
import { useGetUserTypes } from "../../../../modules/Users/apiHooks";
import { Button, Space, Table, Typography } from "antd";
import { Controls } from "./components";
import CreateNewUserType from "./components/CreateNewUserType";
import { useHistory } from "react-router-dom";

const UserTypes = () => {
  const history = useHistory();
  const { data, isLoading } = useGetUserTypes();
  return (
    <MainLayout>
      <PageTemplate>
        <Space style={{ marginBottom: "15px" }}>
          <Typography.Title level={3} style={{ margin: 0 }}>
            {_t("user_types")}
          </Typography.Title>
          <CreateNewUserType />
        </Space>
        {!!data && (
          <Table
            dataSource={data?.data}
            pagination={false}
            columns={[
              {
                title: _t("Name"),
                dataIndex: "title",
                render: (v, data: any) => (
                  <>
                    <Controls data={data} />
                    <b>{v}</b>
                  </>
                ),
              },
            ]}
          />
        )}
        <Button
          type="ghost"
          size="large"
          shape="round"
          htmlType="button"
          onClick={() => history.goBack()}
          style={{ marginTop: "10px" }}
        >
          {_t("Back")}
        </Button>
      </PageTemplate>
    </MainLayout>
  );
};

export default UserTypes;
